import React from 'react'
import { i18nMoment, i18nPath } from 'utils/i18nHelpers'
import UserLink from 'components/common/userLink'
import { FAQType } from 'types/faq'
import classNames from 'classnames'
import RoundedAvatars from 'components/common/roundedAvatars'

const I18N = i18nPath('views.faqs')

type Props = {
  faq: FAQType
  className?: string
}

const FAQLastUpdater = ({ faq, className = '' }: Props) => {
  const {
    author, updatedAt, automaticallyGenerated, faqSources,
  } = faq

  const users = _.uniq(faqSources?.map(fs => fs.user).filter(Boolean) || [])

  if (!automaticallyGenerated) {
    return (
      <div className={classNames('d-flex flex-wrap align-items-center gap-2 text-small text-secondary', className)}>
        <span>{I18N('last_updated_by')}</span>
        <span><UserLink user={author} /></span>
        <span>{i18nMoment(updatedAt).fromNow()}</span>
      </div>
    )
  }

  return (
    <div className={classNames('d-flex flex-wrap align-items-center gap-2 text-small text-secondary', className)}>
      <span>{I18N('generated_by_bot', { count: users.length })}</span>
      {users.length <= 1 ? (
        <span><UserLink user={users[0]} /></span>
      ) : (
        <RoundedAvatars users={users} />
      )}
      <span>{i18nMoment(updatedAt).fromNow()}</span>
    </div>
  )
}

export default FAQLastUpdater
