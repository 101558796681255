import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import BackButton from 'components/common/backButton'
import ClearyCard from 'components/common/card'
import API from 'services/api'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import MultipleEmployeesName from 'components/common/multipleEmployeesName'
import useApi from 'components/common/hooks/useApi'
import useFetch from 'components/common/hooks/useFetch'
import Slider from 'pages/ai_platform/knowledgeIngestionPage/slider'
import TopThreeFAQs from 'pages/ai_platform/knowledgeIngestionPage/topThreeFAQs'
import CdnSvg from 'components/common/cdnSvg'

const I18N = i18nPath('views.ai_assistant.knowledge_ingestion')

export const KnowledgeIngestionTitle = ({ knowledgeIngestion }) => (
  <div className='d-flex gap-2 align-items-baseline'>
    <span className='text-xl bold'>{I18N('title')}</span>
    {knowledgeIngestion?.slackChannelNames.join(', ')}
  </div>
)

const AiPlatformKnowledgeIngestionPage = () => {
  const { knowledgeIngestionId } = useParams()
  const [hasMore, setHasMore] = useState(false)
  const [faqs, setFaqs] = useState<any[]>([])

  const { data: knowledgeIngestion, isLoading } = useFetch(
    () => API.ai.assistant.knowledgeIngestions.fetch(knowledgeIngestionId),
    []
  )

  const [fetchFAQs, { isLoading: isLoadingFAQs, paginationData: faqsPaginationData }] = useApi(
    () => API.ai.assistant.knowledgeIngestions.fetchFAQs(
      knowledgeIngestionId,
      { lastId: faqs[faqs.length - 1]?.id }
    ),
    {
      onSuccess: (faqs, response) => {
        setFaqs(prev => [...prev, ...faqs])
        setHasMore(response.headers['x-has-more'] === 'true')
      },
    }
  )

  useEffect(() => {
    fetchFAQs()
  }, [])

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center'>
        <CirclesLoadingIndicator />
      </div>
    )
  }

  return (
    <div className='AiPlatform AiPlatformKnowledgeIngestionPage d-flex justify-content-center mt-5'>
      <div className='AiPlatformKnowledgeIngestionPage__wrapper d-flex flex-column gap-4'>
        <BackButton url='/dashboard' backToText={I18N('dashboard')} />
        <KnowledgeIngestionTitle knowledgeIngestion={knowledgeIngestion} />

        <Slider knowledgeIngestion={knowledgeIngestion} />
        <TopThreeFAQs knowledgeIngestionId={knowledgeIngestion.id} />

        {faqs && faqs.length > 0 ? (
          <ClearyCard
            title={(
              <>
                <div className='h5'>
                  {I18N('faqs_created_card.title', { count: faqsPaginationData?.total ?? 0 })}
                </div>
                <div>
                  <a href='/pages' className='btn btn-primary'>
                    {I18N('faqs_created_card.cta')}
                  </a>
                </div>
              </>
            )}
          >
            <table>
              <thead>
                <tr>
                  <th className='text-smallest text-secondary w-60'>
                    {I18N('faqs_created_card.table.headers.faq')}
                  </th>
                  <th className='text-smallest text-secondary w-20'>
                    {I18N('faqs_created_card.table.headers.source')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {faqs.map(faq => (
                  <tr key={faq.id}>
                    <td className='text-small question'>
                      <Link to={faq.displayPath}>
                        {faq.question}
                      </Link>
                    </td>
                    <td className='text-small'>
                      <MultipleEmployeesName
                        employees={faq.faqSources?.flatMap(source => source.user)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {isLoadingFAQs && <CirclesLoadingIndicator />}

            {hasMore && (
              <div onClick={fetchFAQs} className='text-small link mt-2'>
                {I18NCommon('load_more')}
              </div>
            )}
          </ClearyCard>
        ) : (
          <div className='faqs-empty-state d-flex flex-column justify-content-center align-items-center gap-4'>
            <CdnSvg src='/images/illustrations/womanWithSpyglass.svg' />
            <div className='text-large bold'>{I18N('empty_faqs.title')}</div>
            <div className='text-normal'>{I18N('empty_faqs.description')}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AiPlatformKnowledgeIngestionPage
